/**
 * Buttons (objects/_buttons.scss)
 */

/* ----- General ----- */
.btn {
	@include link-reset;
	font-family: $font-family-secondary;
	font-weight: 500;
	display: inline-block;
	margin: 0 0 1.25rem 0;
}

/* ----- Variations ----- */
.btn--full-width {
	display: block;
	width: 100%;
}

.btn--rounded {
	border-radius: 3.125rem;
}

.btn--dark {
	color: $color-white;
	background-color: $color-dark-blue;
	padding: .5625rem 1.5625rem;
	transition: color .2s, background-color .2s;

	@media (min-width: $screen-sm-min) {
		padding: .8125rem 2.1875rem;
	}

	&:hover,
	&:focus,
	&:active {
		color: $color-dark-blue;
		background-color: $color-yellow;
	}
}

.btn--w-icon {
	.icon {
		float: right;
		font-size: 1.375rem;
		margin-left: .75rem;
		position: relative;
		bottom: -.125rem;
	}
}

.btn--w-icon-left {
	.icon {
		float: left;
		margin-left: 0;
		margin-right: .75rem;
	}
}

.btn--read-more {
	text-transform: uppercase;

	.icon {
		display: inline-block;
		margin-left: .625rem;
		transition: transform .2s;
	}

	&:hover,
	&:focus,
	&:active {
		.icon {
			@include translate(.3125rem, 0);
		}
	}
}
